import { useRouter } from 'next/router';
import { useBuybackContext } from '@/lib/buyback/BuybackContext';
import { useApi } from '@/components/buyback/services/api';
import {
  BuybackQuoteUS,
  BuybackSalesChannel,
} from '@/components/buyback/common';
import { trackQuoteEvent } from '../analytics/trackQuote';
import { SegmentEvent } from '../analytics/events';
import { logger } from '@/lib/logger';

export const useQuoteSubmit = () => {
  const router = useRouter();
  const api = useApi();
  const { updateFinalQuote } = useBuybackContext();

  const submitQuote = async (
    finalQuote: BuybackQuoteUS,
    isTradeIn?: boolean,
  ): Promise<string> => {
    const payload = { ...finalQuote };

    try {
      const res = await api.postBuybackQuote({
        ...payload,
        salesChannel: isTradeIn
          ? BuybackSalesChannel.TRADE_IN
          : BuybackSalesChannel.BUYBACK,
      });
      const order = res.data.DB;

      const toSet = {
        collectionType: order.collection.method,
        category: order.device.category,
        brand: order.device.brand,
        deviceName: order.device.model,
        vendorPrice: order.quote,
        orderId: order.orderId,
        hasSubmit: true,
      };

      updateFinalQuote({ ...finalQuote, ...toSet });

      logger.info(
        { finalQuote, orderId: order.orderId },
        'Buyback Order Created',
      );

      trackQuoteEvent(SegmentEvent.BUYBACK_PAYMENT_ADDED, {
        ...finalQuote,
        salesChannel: isTradeIn
          ? BuybackSalesChannel.TRADE_IN
          : BuybackSalesChannel.BUYBACK,
      });

      if (
        finalQuote.vendorMailInLocation &&
        finalQuote.vendorName &&
        finalQuote.customer
      ) {
        try {
          const shipmentResponse = await api.generateShipment({
            store: 'reebelo-us',
            orderId: order.orderId,
            vendorAddress: JSON.stringify({
              ...finalQuote.vendorMailInLocation,
              name: finalQuote.vendorName,
            }),
            token: finalQuote.token,
            customer: finalQuote.customer,
          });

          logger.info(
            { finalQuote, orderId: order.orderId },
            'Shipping Label Generated',
          );

          trackQuoteEvent(
            SegmentEvent.BUYBACK_SHIPPING_ADDED,
            {
              ...finalQuote,
              ...toSet,
              shippingDetails: shipmentResponse?.data,
              salesChannel: isTradeIn
                ? BuybackSalesChannel.TRADE_IN
                : BuybackSalesChannel.BUYBACK,
            },
            () => {
              if (!isTradeIn)
                router.push(`/buyback/${order.orderHandle}/thank-you`);
            },
          );

          if (isTradeIn) return `${order.orderHandle}#${order.orderId}`;
        } catch (err) {
          logger.error({ error: err }, 'Error generating shipping label');

          return '';
        }
      }

      if (!isTradeIn) {
        router.push(`/buyback/${order.orderHandle}/thank-you`);

        return '';
      }

      return `${order.orderHandle}#${order.orderId}`;
    } catch (err) {
      logger.error({ error: err }, 'Error creating buyback order');

      return '';
    }
  };

  return { submitQuote };
};
