/* eslint-disable tailwindcss/no-custom-classname */

import Image from 'next/future/image';
import { useState } from 'react';
import { isValidPhoneNumber } from 'libphonenumber-js';
import cn from 'classnames';
import Loading from '@lambda/ui/src/Loading';
import * as yup from 'yup';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import paypalIcon from '@/public/icons/buyback/sell-device/paypal.svg';
import zelleIcon from '@/public/icons/buyback/sell-device/zelle.svg';
import plusIcon from '@/public/icons/buyback/sell-device/plus.svg';
import minusIcon from '@/public/icons/buyback/sell-device/minus.svg';
import { useBuybackContext } from '@/lib/buyback/BuybackContext';
import { useQuoteSubmit } from '@/lib/buyback/QuoteSubmit';
import arrowRightIcon from '@/public/icons/buyback/sell-device/arrow-right.svg';
import { HousingCondition, ScreenCondition } from '../../common';
import { streamlineCondition } from '@/lib/buyback/helpers';

const getValidationSchema = (paymentProcessor: 'Zelle' | 'PayPal' | '') =>
  yup
    .object()
    .shape({
      email:
        paymentProcessor === 'PayPal'
          ? yup
              .string()
              .required('Email is required')
              .email('Invalid Email Address')
          : yup.string().nullable(),
      phone: yup
        .string()
        .required('Phone Number is required')
        .test('isValid', 'Invalid Phone Number', (value) =>
          isValidPhoneNumber(`+1${value}`),
        ),
    })
    .required();

type FormT = yup.InferType<ReturnType<typeof getValidationSchema>>;

const Form = ({
  paymentProcessor,
}: {
  paymentProcessor: 'Zelle' | 'PayPal';
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="mt-4 grid grid-cols-2 gap-2">
      {paymentProcessor === 'PayPal' && (
        <div className="col-span-2 w-full lg:col-span-1">
          <input
            id="email"
            type="text"
            className={cn('buyback-form', {
              'border-2 border-red outline-red': errors.email,
            })}
            placeholder={`${paymentProcessor} Email Address`}
            {...register('email')}
          />
          {errors.email ? (
            <div className="mt-0.5 text-xs text-red">
              {errors.email.message}
            </div>
          ) : (
            ''
          )}
        </div>
      )}
      <div className="col-span-2 w-full lg:col-span-1">
        <div className="flex w-full items-center">
          <div className="flex h-full cursor-not-allowed items-center justify-center rounded-l-lg border border-r-0 border-gray-200 bg-[#FAFAFA] p-3">
            +1
          </div>
          <input
            id="phone"
            type="phone"
            placeholder={`${paymentProcessor} Phone Number`}
            className={cn('buyback-form rounded-l-none', {
              'border-2 border-red outline-red': errors.phone,
            })}
            {...register('phone')}
          />
        </div>
        {errors.phone ? (
          <div className="mt-0.5 text-xs text-red">{errors.phone.message}</div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

const PayoutInformation = ({
  email,
  phone,
  tradeIn,
}: {
  email?: string;
  phone?: string;
  tradeIn?: {
    back: () => void;
    onSubmit: () => void;
  };
}) => {
  const [showPayPal, setShowPayPal] = useState<boolean>(true);
  const [showZelle, setShowZelle] = useState<boolean>(false);
  // eslint-disable-next-line no-nested-ternary
  const paymentProcessor = showPayPal ? 'PayPal' : showZelle ? 'Zelle' : '';
  const form = useForm<FormT>({
    resolver: yupResolver(getValidationSchema(paymentProcessor)),
    defaultValues: {
      email: email ?? '',
      phone: phone ?? '',
    },
  });
  const [loading, setLoading] = useState(false);
  const { submitQuote } = useQuoteSubmit();
  const { finalQuote, resetQuote, updateFinalQuote } = useBuybackContext();
  const [error, setError] = useState(false);

  const onSubmit = form.handleSubmit(async (data) => {
    if (finalQuote) {
      setLoading(true);

      let toSubmit = {
        ...finalQuote,
        screen: streamlineCondition(
          finalQuote.screen,
          'screen',
        ) as ScreenCondition,
        housing: streamlineCondition(
          finalQuote.housing,
          'housing',
        ) as HousingCondition,
        hasSubmit: !tradeIn,
      };

      if (showPayPal) {
        toSubmit = {
          ...toSubmit,
          paymentMethod: 'Paypal',
          paymentDetails: {
            ...finalQuote.paymentDetails,
            paymentMethod: 'Paypal',
            paypalEmail: data.email,
            paypalMobile: `+1${data.phone}`,
          },
        };
      }

      if (showZelle) {
        toSubmit = {
          ...toSubmit,
          paymentMethod: 'Zelle',
          paymentDetails: {
            ...finalQuote.paymentDetails,
            paymentMethod: 'Zelle',
            zelleEmail: '',
            zelleMobile: `+1${data.phone}`,
          },
        };
      }

      if (tradeIn) {
        updateFinalQuote(toSubmit);
        setLoading(false);
        tradeIn.onSubmit();
      } else {
        submitQuote(toSubmit)
          .then(() => {
            resetQuote();
          })
          .catch(() => {
            setError(true);
            setLoading(false);
          });
      }
    }
  });

  const confirmDisabled = !(showPayPal || showZelle);

  return (
    <FormProvider {...form}>
      <div className="">
        {tradeIn && (
          <button
            type="button"
            onClick={() => {
              tradeIn.back();
            }}
            className="mb-3 flex items-center gap-2 font-bold text-[#B1B2B2]"
          >
            <div className="h-6 w-6">
              <Image
                alt="Back"
                src={arrowRightIcon}
                height={50}
                width={50}
                quality={100}
                className="h-full w-auto rotate-180 object-contain"
              />
            </div>
            <span className="block">Back</span>
          </button>
        )}
        <h3 className="text-xl font-bold">
          Please choose your preferred method of payment
        </h3>
        <p className="text-gray-700/70">
          We process payments within 24-hrs of inspection process completion.
        </p>
        <div className="mt-4 rounded-lg border-2">
          <button
            onClick={() => {
              setShowPayPal(!showPayPal);
              setShowZelle(false);
            }}
            className="flex w-full items-center justify-between p-4"
          >
            <div className="h-6">
              <Image
                src={paypalIcon}
                alt="PayPal"
                className="h-full w-auto object-contain"
              />
            </div>
            {showPayPal ? (
              <div className="h-4 w-4">
                <Image
                  src={minusIcon}
                  alt="Collapse"
                  className="h-full w-full object-contain"
                />
              </div>
            ) : (
              <div className="h-4 w-4">
                <Image
                  src={plusIcon}
                  alt="Expand"
                  className="h-full w-full object-contain"
                />
              </div>
            )}
          </button>
          {showPayPal && (
            <div className="px-4 pb-4">
              <p>
                We'll credit your PayPal account once your item(s) have been
                verified by our staff. Your email address or phone number must
                be associated with your PayPal or bank account AND set up to
                receive payments. Please contact your bank if you are unsure of
                it's status as this may delay receiving payments.
              </p>
              <Form paymentProcessor="PayPal" />
            </div>
          )}
        </div>
        <div className="mt-2 rounded-lg border-2">
          <button
            onClick={() => {
              setShowZelle(!showZelle);
              setShowPayPal(false);
            }}
            className="flex w-full items-center justify-between p-4"
          >
            <div className="h-6">
              <Image
                src={zelleIcon}
                alt="Zelle"
                className="h-full w-auto object-contain"
              />
            </div>
            {showZelle ? (
              <div className="h-4 w-4">
                <Image
                  src={minusIcon}
                  alt="Collapse"
                  className="h-full w-full object-contain"
                />
              </div>
            ) : (
              <div className="h-4 w-4">
                <Image
                  src={plusIcon}
                  alt="Expand"
                  className="h-full w-full object-contain"
                />
              </div>
            )}
          </button>
          {showZelle && (
            <div className="px-4 pb-4">
              <p>
                We'll credit your Zelle ® account once your item(s) have been
                verified by ourstaff. Your phone number must be associated with
                your Zelle ® account AND setup to receive payments. Please
                contact your bank if you are unsure of it's status as this may
                delay receiving payments.
              </p>
              <Form paymentProcessor="Zelle" />
            </div>
          )}
        </div>
        <button
          className="mt-4 flex h-12 w-full items-center justify-center rounded-lg bg-gray-700 px-3 font-bold text-white transition duration-150 ease-in-out disabled:cursor-not-allowed disabled:opacity-50"
          onClick={onSubmit}
          disabled={confirmDisabled || loading}
        >
          {loading ? <Loading /> : 'Confirm'}
        </button>

        {error ? (
          <div className="mt-2 font-bold text-red">
            Error: Please try again.
          </div>
        ) : (
          ''
        )}
      </div>
    </FormProvider>
  );
};

export default PayoutInformation;
